import { useSettings } from '@backpackjs/storefront';
import Link from 'next/link';
import { useRouter } from 'next/router';
import EmailSignup from './EmailSignup';
import CountrySelector from './CountrySelector';

import WhiteBLogo from '../../icons/WhiteBLogo';
import WhiteInstagram from '../../icons/WhiteInstagram';
import WhiteFacebook from '../../icons/WhiteFacebook';
import WhiteTikTok from '../../icons/WhiteTikTok';
import FooterMenu from './FooterMenu';
import { QuizBanner } from './QuizBanner';

function Footer() {
  const settings = useSettings();
  const router = useRouter();

  const hiddenPages = settings?.footer?.quizBanner?.bannerInvisibility;
  const hideQuiz = hiddenPages?.find((page) => page?.link?.url === router.asPath) ? true : false;

  return (
    <>
    {!hideQuiz &&
      <QuizBanner />
    }
    <footer className="bg-black">
      <div className="my-0 mx-auto flex w-full max-w-[1180px] items-center pl-8 pt-4 md:pt-2 lg:pb-4">
        <div className="my-0 mx-auto flex w-full max-w-[432px] items-center md:max-w-full">
          <div className="flex pt-4 pb-4 pr-4 sm:pt-6 sm:pb-6 md:w-auto md:pb-8 md:pr-10 lg:pt-8">
            <a className="w-[32px] md:w-[48px]" href="/" aria-label="briogeohair logo">
              <WhiteBLogo />
            </a>
          </div>
          <div className="flex pt-2 pr-6 pb-2 pl-6 md:w-auto md:pt-0 md:pb-0 md:pl-8">
            <p className="footer-tagline md:h3 mb-0 font-tiempos text-h3-m font-light text-white md:mb-0" role="heading">
              {settings?.footer?.tagline}
            </p>
          </div>
        </div>
      </div>

      <div className="border-b border-white px-8 md:px-0 md:pb-6">
        <div>
          <div className="my-0 mx-auto flex max-w-[1180px] flex-col justify-between md:flex-row md:pl-8">
            <div className="flex items-center justify-center md:mr-12 md:w-1/2 md:justify-start">
              <FooterMenu />
            </div>
            <div className="flex items-start justify-center pb-6 md:w-1/2 md:py-0 md:py-8 md:pt-0 md:pr-8 lg:px-0">
              <div className="flex w-full max-w-[432px] flex-col justify-center">
                <EmailSignup />
                <div className="flex">
                  <a
                    target="blank"
                    href="https://www.instagram.com/briogeo/"
                    className="mr-6"
                    aria-label="Instagram"
                    aria-hidden
                  >
                    <WhiteInstagram />
                  </a>
                  <a
                    target="blank"
                    href="https://www.facebook.com/Briogeo"
                    className="mr-6"
                    aria-label="Facebook"
                    aria-hidden
                  >
                    <WhiteFacebook />
                  </a>
                  <a
                    target="blank"
                    href="https://www.tiktok.com/@briogeo?lang=en"
                    aria-label="Tiktok"
                    aria-hidden
                  >
                    <WhiteTikTok />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="flex items-center justify-center">
          <div className="flex justify-center py-4">
            <span className="text-xsmall pr-2 pl-2 text-white">
              &copy;{new Date().getFullYear()} Briogeo
            </span>
            {settings?.footer?.footerSmallMenu?.length > 0 && (
              <ul className="m-0 flex list-none p-0">
                {settings?.footer?.footerSmallMenu.map(
                  (footerSmallMenuLink, footerSmallMenuLinkIndex) => (
                    <li
                      className="mt-0 flex"
                      key={`menu-item-${footerSmallMenuLinkIndex}`}
                    >
                      {footerSmallMenuLink?.link && (
                        <Link href={footerSmallMenuLink?.link?.url}>
                          <a className="text-xsmall pr-2 pl-2 text-white text-white no-underline hover:text-grey-7 hover:underline">
                            {footerSmallMenuLink?.link?.text}
                          </a>
                        </Link>
                      )}
                    </li>
                  )
                )}
              </ul>
            )}
          </div>
        </div>
        <CountrySelector />
      </div>
    </footer>
    </>
  );
}

export default Footer;
