import Head from 'next/head';
import Script from 'next/script';

import {
  useStorefrontInit,
  useCartAddDiscountUrl,
} from '@backpackjs/storefront';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Header from '../snippets/globals/layout/Header';
import Footer from '../snippets/globals/layout/Footer';
import SkipLink from '../snippets/globals/layout/SkipLink';
import RootStyles from '../snippets/globals/RootStyles';
import DiscountBanner from '@/snippets/globals/layout/DiscountBanner';
import { DataLayerEventListeners } from '@/snippets/components/DataLayerEventListeners';
import { Fueled } from '@/snippets/components/Fueled';
import Visually from '@/snippets/components/Visually';
import HeadMeta from '@/snippets/globals/layout/HeadMeta';
import GeoLocation from '@/snippets/components/GeoLocation';
import { useShopifyAnalytics } from '@hooks/shopifyAnalytics';
import {usePackCookie} from '@hooks';

import { QuickViewModal } from '@/snippets/globals/layout/QuickViewModal';
import GiftSelector from '@/snippets/cart/GiftSelector';

function StorefrontLayout({ ...props }) {
  const { ContentForLayout, settings, page } = props;
  const { events, asPath } = useRouter();
  // initialize customer, cart, shop and markets
  useStorefrontInit();

  // send page view and add to cart events to Shopify analytics
  useShopifyAnalytics({ collection: props.collection, product: props.product });

  // auto applies cart discount found on the landing url
  useCartAddDiscountUrl();

  // set pack session cookie
  usePackCookie();

  const fadeIntoWhite = () => {
    document.querySelector('main').classList.add('is-loading');
  };

  const fadeOutOfWhite = () => {
    document.querySelector('main').classList.remove('is-loading');
  };

  const preloadImages = settings?.settings?.preloadImages?.split(',')?.map((url) => url.trim()) || [];

  useEffect(() => {
    events.on('routeChangeStart', fadeIntoWhite);
    events.on('routeChangeComplete', fadeOutOfWhite);

    return () => {
      events.off('routeChangeStart', fadeIntoWhite);
      events.off('routeChangeComplete', fadeOutOfWhite);
    };
  });

  return (
    <>
      <Head>
        <HeadMeta />
        <meta
          key="page:fb:image"
          property="og:image"
          content={
            page?.seo?.image ||
            props.product?.featuredImage?.src ||
            settings?.settings?.globalShareImage?.image?.src
          }
        />
        {preloadImages ? preloadImages.map((url) => (
          <link
            rel="preload"
            as="image"
            href={url}
          />
        ))
        : null}
      </Head>
      {/* Store Facebook URL params in cookies on mount. Added by Fueled. */}
      <script
        id="url-params-cookie-script"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          (function () {
            const maxAge = '7776000';
            const getDomain = (url) => {
              const domainParts = url.split('.');
              return domainParts.length > 2 ? domainParts.slice(-2).join('.') : url;
            };
            const getSubdomainIndex = (url) => {
              if (!url) return 1;
              const parts = url.split(".");
              if (parts[0] === 'www') return 1;
              return parts.length === 1 ? 0 : parts.length === 2 ? 1 : 2;
            };
            const subdomainIndex = getSubdomainIndex(window.location.host);
            const generateFbc = (fbclid) => 'fb.' + subdomainIndex + '.' + Date.now() + '.' + fbclid;

            const fbclid = new URLSearchParams(window.location.search).get('fbclid');
            if (fbclid) {
              const fbc = generateFbc(fbclid);
              document.cookie = '_fbc=' + fbc + '; path=/; domain=.' + getDomain(window.location.hostname) + '; max-age=' + maxAge + '; samesite=lax';
            }
          })();`,
        }}
      />

      {/* VISUALLY SDK */}
      <Script
        src="https://live.visually-io.com/widgets/vsly-preact.min.js?k=js.9053204&e=2&s=BRIOGEO_HAIR_CARE"
        strategy="beforeInteractive"
        rel="preconnect prefetch"
      />

      <Script
        src="https://live.visually-io.com/v/visually-spa.js"
        strategy="beforeInteractive"
        rel="preconnect prefetch"
      />

      <Script
        src="https://live.visually-io.com/v/visually-a-spa.js"
        defer
      />

      <Visually />

      <DataLayerEventListeners {...props} />

      <script src="https://cdn.shopify.com/shopifycloud/privacy-banner/storefront-banner.js" />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            privacyBanner.loadBanner({
              storefrontAccessToken: "${process.env.SHOPIFY_STOREFRONT_API_TOKEN}",
              checkoutRootDomain: "shop.briogeohair.com",
              storefrontRootDomain: "${process.env.SHOPIFY_DOMAIN}",
            }).then(() => {
              console.log('loaded');
            }).catch((error) => {
              console.error('error', error);
            });
          `,
        }}
      />

      <Fueled />

      {asPath?.indexOf('/pages/privacy-policy') === -1 && (
        <>
          <Script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            charset="UTF-8"
            data-domain-script="01936375-4867-7f55-a10f-2e17bf6f641a" />

          <Script type="text/javascript">
            function OptanonWrapper() { }
          </Script>
        </>
      )}

      <Script
        src="https://js.afterpay.com/afterpay-1.x.js"
        strategy="beforeInteractive"
      />
      <Script
        src="https://cdn-widgetsrepository.yotpo.com/v1/loader/sjzt7N108GUzJ96g4WqUxg"
        async
      />

      <Script
        dangerouslySetInnerHTML={{
          __html: `window.Shopify = {
              shop: "briogeo-hair-care.myshopify.com",
              currency: {
                active: "USD",
                rate: "1.0"
              }
            }`,
        }}
      />

      <Script
        id="beamCartImport"
        type="module"
        dangerouslySetInnerHTML={{
          __html: `
        import { updateCart } from 'https://sdk.beamimpact.com/web-sdk/v1.40.0/dist/integrations/cart.js'
        window.beamUpdateCart = updateCart;
      `,
        }}
      />

      {/* Global-e replacement cookie script */}
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          var createReplacementCookie = function (cookieDomain) {
            function getParameterByName(name, url = window.location.href) {
                name = name.replace(/[\\[\\]]/g, '\\$&');
                var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\\+/g, ' '));
            }

            function ticksToDate(ticks) {
                return Number.isInteger(ticks) ? new Date(ticks / 1e+4 + new Date('0001-01-01T00:00:00Z').getTime()) : null;
            }

            //* If URL contains the query parameter replacementExpire, create cookie GE_Replacement
            let replacementExpireParam = parseInt(getParameterByName('replacementExpire'));
            if (replacementExpireParam !== null && replacementExpireParam > Date.now()) {
                var cookieStringifiedValue = encodeURIComponent(JSON.stringify({ glCountry: getParameterByName('glCountry'), glCurrency: getParameterByName('glCurrency') }));
                var expirationInUTC = ticksToDate(replacementExpireParam).toUTCString();
                document.cookie = 'GE_Replacement=' + cookieStringifiedValue + ';expires=' + expirationInUTC + ';path=/;domain=.' +cookieDomain;
              }
            };

            var cookieDomain = window.location.hostname
            //can require custom value on stores whene checkout page domain is different
            createReplacementCookie(cookieDomain);`,
        }}
      />

      <Script
        type="text/javascript"
        id="ship-scout-config"
        strategy="beforInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window._shipScout = window._shipScout || [];
            window.Shopify = window.Shopify || {};

            Shopify.shop = 'briogeo-hair-care.myshopify.com';

            window.shipScoutCookieDomain = document.domain.indexOf('netlify') > -1 ? 'netlify.app' : 'briogeohair.com';
          `,
        }}
      />
      <Script
        type="text/javascript"
        id="ship-scout-script"
        strategy="afterInteractive"
        src="https://web.shipscout.app/app.min.js"
      />

      <RootStyles />

      <SkipLink />

      <DiscountBanner />

      <Header />

      <main id="main-content" className="main-container">
        <ContentForLayout {...props} />
      </main>
      <QuickViewModal />
      <Footer />
      <GeoLocation />
      <GiftSelector />

      <Script
        type="text/javascript"
        src="https://cdn.swellrewards.com/loader/sjzt7N108GUzJ96g4WqUxg.js"
      />

      <Script src="https://cdn.crazyrocket.io/widget/scripts/crazyrocket.start.js?id=64e37206117cee01887d7b67&shop=briogeo-hair-care.myshopify.com" />

      <Script
        id="gorgias-chat-widget-install-v2"
        src="https://config.gorgias.chat/gorgias-chat-bundle-loader.js?applicationId=10623"
      />
      <Script src="https://static.myshlf.us/Affiliates/sms_aff_clicktrack.js" />

      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          (function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'left', leadColor : '#444444', triggerColor : '#444444', triggerRadius : '50%', triggerPositionX : 'left', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'bottom', triggerOffsetX : 20, triggerOffsetY : 80, mobile : { triggerSize : 'small', triggerPositionX : 'left', triggerPositionY : 'bottom', triggerOffsetX : 10, triggerOffsetY : 80, triggerRadius : '20' } }); }; h.appendChild(s); })();`,
        }}
      />

      {/* Temporary removal of the cart widget
      <Script async
        fetchOnMount={true}
        src='https://code.buywithprime.amazon.com/bwp.v1.js'/>
      <div
          id="amzn-bwp-cart"
          data-site-id="gwfxnozf54"
          data-widget-id="w-XRZsc7Ov0c7a7UehqIg5N0"
      ></div> */}
    </>
  );
}

// eslint-disable-next-line import/no-default-export
export default StorefrontLayout;
