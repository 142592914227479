import Slider from 'react-slick';
import { ArrowLeft } from '@/snippets/icons/ArrowLeft';
import { ArrowRight } from '@/snippets/icons/ArrowRight';
import { Hotspot } from './Hotspot';
import { scrollToSection } from '@/utils/Helper';
import { useEffect } from 'react';

export function CarouselHotspots({ cms }) {
  let slider = null;
  const { aboveTheFold = false } = cms;

  const next = () => {
    if (slider) slider.slickNext();
  };
  const prev = () => {
    if (slider) slider.slickPrev();
  };

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    swipe: true,
    centerMode: false,
    useTransform: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  useEffect(() => {
    scrollToSection(
      `${CarouselHotspots.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`
    );
  });

  return (
    <div
      data-comp={CarouselHotspots.displayName}
      className="hotspots px-4 py-10 md-:px-12 md-:pb-20 lg:pt-20 lg:pb-[120px]"
      id={`${CarouselHotspots.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
    >
      <style>
        {`#${CarouselHotspots.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${CarouselHotspots.Schema.key}-${
          cms.id || cms.cmsId || cms.tinaId
        } {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div className="hotspots-header mb-6 flex items-end justify-between md-:justify-center lg:mb-8 2xl:mb-11">
        {cms?.title && (
          <h3 className="text-header hotspots-title mb-0">{cms.title}</h3>
        )}
      </div>
      <div className="hotspots-body relative flex items-center justify-center">
        {cms?.slides?.length > 1 && (
          <button
            type="button"
            className="hotspots__arrow hotspots__arrow--prev absolute top-[-62px] right-[44px] flex flex-shrink-0 md:relative md:top-auto md:right-auto md:mb-11 md:mr-6"
            onClick={prev}
            aria-label='View previous hotspots'
          >
            <span className='sr-only'>View previous hotspots</span>
            <ArrowLeft />
          </button>
        )}
        <div className="w-full flex-grow md-:w-[calc(100%-126px)] lg:w-[calc(100%-138px)] 2xl:max-w-[1120px]">
          {cms?.slides?.length > 0 && (
            <Slider ref={(c) => (slider = c)} {...settings}>
              {cms.slides.map((slide, slideIdx) => (
                <div
                  className="relative w-full border border-grey-1"
                  key={`slide-item-${slideIdx}`}
                >
                  {slide?.bg_image_d && (
                    <img
                      className="!hidden w-full lg:!block"
                      src={slide.bg_image_d.src}
                      alt={slide.alt_text}
                      loading={aboveTheFold ? 'eager' : 'lazy'}
                      priority={aboveTheFold}
                      fetchpriority={aboveTheFold ? 'high' : 'low'}
                    />
                  )}
                  {!slide?.bg_image_d && slide?.bg_image_m && (
                    <img
                      className="!hidden w-full lg:!block"
                      src={slide.bg_image_m.src}
                      alt={slide.alt_text}
                      loading={aboveTheFold ? 'eager' : 'lazy'}
                      priority={aboveTheFold}
                      fetchpriority={aboveTheFold ? 'high' : 'low'}
                    />
                  )}
                  {slide?.bg_image_m && (
                    <img
                      className="w-full lg:!hidden"
                      src={slide.bg_image_m.src}
                      alt={slide.alt_text}
                      loading={aboveTheFold ? 'eager' : 'lazy'}
                      priority={aboveTheFold}
                      fetchpriority={aboveTheFold ? 'high' : 'low'}
                    />
                  )}
                  {!slide?.bg_image_m && slide?.bg_image_d && (
                    <img
                      className="w-full lg:!hidden"
                      src={slide.bg_image_d.src}
                      alt={slide.alt_text}
                      loading={aboveTheFold ? 'eager' : 'lazy'}
                      priority={aboveTheFold}
                      fetchpriority={aboveTheFold ? 'high' : 'low'}
                    />
                  )}
                  {slide?.heading && (
                    <div className="absolute -top-px -left-px flex items-center border border-grey-1 bg-white py-2 px-4 lg:py-4 lg:px-8">
                      <h6 className="m-0 text-center lg:min-w-[90px]">
                        {slide.heading}
                      </h6>
                    </div>
                  )}
                  {slide?.hotspots?.map((hotspot, hotspotIdx) => (
                    <Hotspot
                      data={hotspot}
                      key={`hotspot-item-${hotspotIdx}`}
                    />
                  ))}
                </div>
              ))}
            </Slider>
          )}
        </div>
        {cms?.slides?.length > 1 && (
          <button
            type="button"
            className="hotspots__arrow hotspots__arrow--next absolute top-[-62px] right-0 flex flex-shrink-0 md:relative md:top-auto md:right-auto md:mb-11 md:ml-6"
            onClick={next}
            aria-label='View next hotspots'
          >
            <span className='sr-only'>View next hotspots</span>
            <ArrowRight />
          </button>
        )}
      </div>
    </div>
  );
}

CarouselHotspots.displayName = 'CarouselHotspots';

CarouselHotspots.Schema = {
  category: 'Modules',
  label: CarouselHotspots.displayName,
  key: 'CarouselHotspots',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      component: 'toggle',
      name: 'aboveTheFold',
      label: 'Above the fold',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: false,
    },
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'slides',
      label: 'Slides',
      component: 'group-list',
      fields: [
        {
          name: 'bg_image_d',
          label: 'Background Image (Desktop)',
          component: 'image',
          description: 'Recommended Image Size: 2232px x 1200px',
        },
        {
          name: 'bg_image_m',
          label: 'Background Image (Mobile)',
          component: 'image',
          description: 'Recommended Image Size: 1092px x 1092px',
        },
        {
          name: 'alt_text',
          label: 'Alt',
          description: 'Add description of image here. For ADA',
          component: 'text',
          defaultValue: 'Placeholder alt text for hotspot image',
        },
        {
          name: 'heading',
          label: 'Heading',
          component: 'text',
        },
        {
          name: 'hotspots',
          label: 'Hotspots',
          component: 'group-list',
          fields: [
            {
              name: 'x_pos',
              label: 'X Position',
              component: 'number',
              description: 'The percent of x position in width',
            },
            {
              name: 'y_pos',
              label: 'Y Position',
              component: 'number',
              description: 'The percent of y position in height',
            },
            {
              name: 'x_pos_m',
              label: 'X Position (Mobile)',
              component: 'number',
              description: 'The x position for mobile image',
            },
            {
              name: 'y_pos_m',
              label: 'Y Position (Mobile)',
              component: 'number',
              description: 'The y position for mobile image',
            },
            {
              name: 'label',
              label: 'Hotspot Label',
              component: 'text',
              description: 'Hotspot Label will be shown in desktop only',
            },
            {
              component: 'select',
              name: 'style',
              label: 'Hotspot Label Color',
              options: [
                { label: 'Dark', 
                  value: 'dark-label' 
                },
                {
                  label: 'Light',
                  value: 'light-label',
                },
                {
                  label: 'Pink',
                  value: 'pink-label',
                },
              ],
              defaultValue: 'dark-label',
            },
            {
              name: 'label_direction',
              label: 'Hotspot Direction',
              component: 'select',
              description: 'Hotspot Direction will be used in desktop only',
              options: [
                { label: 'Left', value: 'left' },
                { label: 'Top', value: 'top' },
                { label: 'Right', value: 'right' },
                { label: 'Bottom', value: 'bottom' },
              ],
              defaultValue: 'left',
            },
            {
              name: 'modal_heading',
              label: 'Modal Heading',
              component: 'text',
            },
            {
              name: 'modal_body',
              label: 'Modal Body',
              component: 'textarea',
            },
          ],
        },
      ],
    },
  ],
};
