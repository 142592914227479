import { useEffect } from 'react';
import Script from 'next/script';
import { useCartId, useCartUpdateAttributes } from '@backpackjs/storefront';

const fueledScriptUrl = process.env.NEXT_PUBLIC_FUELED_SCRIPT_URL || 'https://mb-cdn.fueled.io/fueled/53616c7465645f5fcaa90562d5c47b67f0bf839814184b25bbd6cc8495722e955af76bdd9f94440c3b77ce32952c84ab40e260d4301faab4d3ef98b522323d4c/packdigital/js';

const eventTypes = [
  'dl_add_to_cart',
  'dl_view_item',
  'dl_select_item',
  'dl_view_item_list',
  'dl_view_search_results',
  'dl_view_cart',
  'dl_remove_from_cart',
  'dl_sign_up',
  'dl_login',
  'dl_subscribe',
  'dl_route_update',
];

export function FueledClientScript() {
  const cartId = useCartId();
  const { cartUpdateAttributes } = useCartUpdateAttributes();

  // accumulate events until the fueled script is loaded
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.packdigital = window.dataLayer?.packdigital || [];

    const eventHandler = (event) => {
      window.dataLayer.packdigital.push({
        type: event.type,
        detail: event.detail,
      });
    };

    eventTypes.forEach((eventType) => {
      window.addEventListener(eventType, eventHandler);
    });

    return () => {
      eventTypes.forEach((eventType) => {
        window.removeEventListener(eventType, eventHandler);
      });
    };
  }, []);

  useEffect(() => {
    if (!cartId) return () => null;

    const setCartToken = async () => {
      const fueled = window.fueled || {};

      const res = await fueled.setFrontendStorage(
        fueled.config.frontendStorageUrl,
        cartId.replace('gid://shopify/Cart/', '')
      );

      const {
        context: {
          ga4: { clientId = undefined, sessionId = undefined } = {},
          fb: { fbp = undefined, fbc = undefined } = {},
        },
      } = await res.json();

      await cartUpdateAttributes({
        attributes: [
          {
            key: 'fueled_context',
            value: JSON.stringify({ga4: {clientId, sessionId}, fb: {fbp, fbc}}),
          },
        ],
      });
    };

    if (window.fueled) {
      setCartToken();
    } else {
      window.addEventListener('fueled-ready', setCartToken);
    }

    return () => {
      window.removeEventListener('fueled-ready', setCartToken);
    };
  }, [cartId]);
  
  return <Script type="text/javascript" src={fueledScriptUrl} />;
}