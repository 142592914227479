import Link from 'next/link';
import { newLineToBr } from '@/utils/Helper';
import styles from './TableBuilder.module.css';

const TableRow = ({children}) => {
  return (
    <tr className="">{children}</tr>
  )
}

const TableColumnNode = (props) => {
  const {children, isHeading = false} = props;
  return isHeading ? (
    <th {...props}>{children}</th>
  ) : (
    <td {...props}>{children}</td>
  )
}

const TableColumn = ({column, isHeading, rowIndex = 0, colspan}) => {
  const { image, title, description } = column;

  return (
    <TableColumnNode
      className={`${rowIndex === 0 ? '' : 'border-t'} border-0 border-grey-1 py-5 last:border-r-0`}
      isHeading={isHeading}
      colspan={colspan > 1 ? colspan : 1}
    >
      <div className={`flex w-full gap-6 ${isHeading ? 'items-center' : ''}`}>
        {image && (
          <div className="w-8 flex-shrink-0">
            <img
              className="block aspect-square w-8 h-8 max-w-full"
              loading="lazy"
              src={image.src}
              alt={image.alt}
            />
          </div>
        )}
        <div className="text-left flex-grow">
          {title && (
            <>{title}</>
          )}
          {description && (
            <div className="text-xsmall mb-0" dangerouslySetInnerHTML={{ __html: newLineToBr(description) }} />
          )}
        </div>
      </div>
    </TableColumnNode>
  )
}

function Section({ cms }) {
  const { title, description, headingAlign = 'text-center', tableLayout = 'table-auto', tableRow } = cms;
  const columnCount = tableRow?.[0]?.['tableColumn']?.length || 0;

  const tableRows = tableRow || [];
  const maxColumns = Math.max(...tableRows.map(row => row?.tableColumn?.length || 0));

  return (
    <div
      id={`${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      data-comp={Section.displayName}
      className="px-4 py-6 md:px-12"
    >
      <style>
        {`#${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div className="mx-auto max-w-[1120px]">
        <div className={`w-full ${headingAlign} ${headingAlign == 'text-center' ? 'max-w-[90%] mx-auto' : ''}`}>
          {title && <h2 className="text-h2-m md:text-h3 mt-0 mb-2 md:mb-3 leading-[1.25]">{title}</h2>}
          {description && (
            <p
              className="m-0 text-sm md:text-base font-light"
              dangerouslySetInnerHTML={{ __html: newLineToBr(description) }}
            />
          )}
        </div>
        {tableRows?.length && columnCount > 0 ? (
          <div className="mt-11 table-wrapper pb-11">
            <table className={`${tableLayout} ${styles.tableBuilder}`} style={{'--columns': maxColumns /*width: `max(100%, ${maxColumns *372}px)`*/}}>
              <thead>
                <tr>
                  {(tableRows?.[0]?.tableColumn || []).map((column, i) => {
                    const colLeng = tableRows?.[0]?.tableColumn?.length || 0;
                    const colSpan = (maxColumns > colLeng > 0) && (i === colLeng -1) ? (maxColumns + 1 - colLeng) : 1;
                    return (
                      <TableColumn key={`col-heading-${i}`} column={column} isHeading colspan={colSpan} />
                    )
                  })}
                </tr>
              </thead>
              <tbody>
              {tableRows?.slice(1)?.map((row, index) => {
                return (
                  <tr key={`row-${index}`}>
                    {(row?.tableColumn || []).map((column, i) => {
                      const colLeng = row?.tableColumn.length || 0;
                      const colSpan = (maxColumns > colLeng > 0) && (i === colLeng -1) ? (maxColumns + 1 - colLeng) : 1;
                      return (
                        <TableColumn key={`col-${index}-${i}`} column={column} rowIndex={index} colspan={colSpan} />
                      )
                    })}
                  </tr>
                );
              })}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  );
}

Section.displayName = 'Table Builder';

const tableColumn = {
  name: 'tableColumn',
  label: 'table Column',
  component: 'group-list',
  itemProps: {
    label: '{{item.title}}',
  },
  fields: [
    {
      name: 'image',
      label: 'Icon',
      component: 'image',
      description: 'Recomended image size 100px x 100px',
    },
    {
      name: 'title',
      label: 'Title',
      description: 'Optional Field',
      component: 'text',
    },
    {
      name: 'description',
      label: 'Description',
      description: 'Optional Field',
      component: 'textarea',
    },
  ],
}

Section.Schema = {
  category: 'Modules',
  label: Section.displayName,
  key: 'table-builder',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      name: 'title',
      label: 'Section Title',
      component: 'text',
    },
    {
      name: 'description',
      label: 'Section Description',
      component: 'textarea',
    },
    {
      component: 'select',
      name: 'headingAlign',
      label: 'Heading Alignment',
      options: [
        { label: 'Left', value: 'text-left' },
        { label: 'Center', value: 'text-center' },
        { label: 'Right', value: 'text-right' },
      ],
      defaultValue: 'center',
    },
    {
      component: 'select',
      name: 'tableLayout',
      label: 'Table Layout',
      options: [
        { label: 'Auto', value: 'table-auto' },
        { label: 'Fixed', value: 'table-fixed' },
      ],
      defaultValue: 'table-auto',
    },
    {
      name: 'tableRow',
      label: 'Table Row',
      component: 'group-list',
      fields: [
        tableColumn,
      ],
    },
  ],
};

export const TableBuilder = Section;
