import { useState, useMemo } from 'react';
import Slider from 'react-slick';
import Head from 'next/head';
import Link from 'next/link';
import { useCountry, useSettings } from '@backpackjs/storefront';
import FsLightbox from '@/utils/fslightbox-react-pro';
import { getSizedImageUrl } from '../../utilities/Images';
import {
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconZoomIn,
} from '@/snippets/index';
import { useWindowDimensions, compact } from '../../utilities/Helper';
import ProductBadge from '@/snippets/products/ProductBadge';
import ProductCustomBadges from '@/snippets/products/ProductCustomBadges';
import BuyWithPrimeBadge from './BuyWithPrimeBadge';

function NextArrow({ className, onClick, quickView }) {
  return (
    <button
      type="button"
      className={`${className} slick-next pdp-thumbnails-slider-next absolute bottom-0 border border-black border-r-transparent ${
        !quickView ? 'left-2 h-12 w-[50px]' : 'left-0 h-10 w-[39px]'
      }`}
      aria-label="View next image"
      onClick={onClick}
    >
      <IconArrowDown />
    </button>
  );
}

function PrevArrow({ className, onClick, quickView }) {
  return (
    <button
      type="button"
      className={`${className} slick-prev pdp-thumbnails-slider-prev absolute bottom-0 rotate-180 border border-black ${
        !quickView ? 'right-2 h-12 w-[50px]' : 'right-0 h-10 w-[42px]'
      }`}
      aria-label="View previous image"
      onClick={onClick}
    >
      <IconArrowDown />
    </button>
  );
}

function ProductGallery({ product, quickView = false, bundleBuilder = false }) {
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const taggedBWP = product.tags.indexOf('BWP') > -1;
  const settings = useSettings();
  const bwpSettings = settings?.buyWithPrime;
  const { country } = useCountry();
  const isUs = country?.isoCode === 'US';
  let showBWPBadge = false;

  if (bwpSettings?.bwpLocation !== 'disabled' && taggedBWP && isUs) {
    showBWPBadge = true;
  }

  const isHorizontal = false;
  const mgSettings = {
    mobileFirst: true,
    infinite: true,
    centerMode: false,
    centerPadding: '0',
    arrows: false,
    dots: false,
    swipeToSlide: true,
    swipe: true,
    adaptiveHeight: true,
    asNavFor: slider2 || null,
    prevArrow: (
      <button
        type="button"
        className="border-0"
        aria-label="View previous image"
      >
        <IconArrowLeft />
      </button>
    ),
    nextArrow: (
      <button type="button" className="border-0" aria-label="View next image">
        <IconArrowRight />
      </button>
    ),
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: true,
          swipeToSlide: false,
          swipe: true,
          centerMode: !isHorizontal,
          centerPadding: 0,
          fade: true,
          waitForAnimate: false,
        },
      },
    ],
  };
  const thumbSettings = {
    infinite: true,
    mobileFirst: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    waitForAnimate: false,
    asNavFor: slider1 || null,
    focusOnSelect: true,
    vertical: true,
    verticalSwiping: true,
    resizeObserver: true,
    prevArrow: <PrevArrow quickView={quickView} />,
    nextArrow: <NextArrow quickView={quickView} />,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 5.3,
          arrows: false,
          focusOnSelect: true,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: bundleBuilder ? 4 : 4.5,
          arrows: false,
          focusOnSelect: true,
          swipeToSlide: true,
        },
      },
    ],
  };

  const { vpWidth } = useWindowDimensions();

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });
  const [thumbsSliderReady, setThumbsSliderReady] = useState(false);
  const handleFsLightbox = (evt) => {
    evt.preventDefault();

    if (quickView) return false;

    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: parseInt(evt.target.dataset.index, 10),
    });
  };
  let productImages = product?.media.map((media, index) => {
    let image = null;
    if (media?.mediaContentType === 'IMAGE') {
      const image_alt = media?.altText || '';
      let image_video = '';
      let video_url = '';
      if (image_alt.indexOf('Video: ') !== -1) {
        image_video = image_alt;
        image_video = image_video.replace(
          /Video:\s|Standard\sSize|Jumbo\sSize|\,|\s/gi,
          ''
        );
      }
      if (image_video.includes('youtube.com')) {
        video_url = image_video;
      } else if (image_video.includes('vimeo.com')) {
        const image_video_id = image_video.replace('https://vimeo.com/', '');
        video_url = `https://player.vimeo.com/video/${image_video_id}`;
      }
      image = { ...media?.image, video: video_url };
    } else if (media?.mediaContentType === 'VIDEO') {
      let video_url = '';
      for (let i = 0; i < media.sources.length; i++) {
        if (media.sources[i].format === 'mp4') {
          video_url = media.sources[i].url;
          break;
        }
      }
      image = { ...media?.preview?.image, video: video_url };
    }
    return image;
  });
  productImages = compact(productImages);

  const sourcesFSLB = productImages.map((productImage, index) => {
    const image_video = productImage?.video || '';
    if (image_video !== '') {
      if (image_video.includes('youtube.com') || image_video.includes('.mp4')) {
        return image_video;
      }
      if (image_video.includes('vimeo.com')) {
        return (
          <iframe
            src={`${image_video}`}
            className="pdp-slider-iframe"
            width="1920"
            height="1080"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        );
      }
    } else {
      return productImage?.src;
    }
  });
  const thumnsFSLB = productImages.map((productImage, index) => {
    return getSizedImageUrl(productImage?.src, '115x115@2x');
  });

  let thumbnails_fix = false;
  if (
    productImages.length <= 5 ||
    (vpWidth <= 1224 && productImages.length <= 4)
  ) {
    thumbnails_fix = true;
  }

  const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return partiallyVisible
      ? ((top > 0 && top < innerHeight) ||
          (bottom > 0 && bottom < innerHeight)) &&
          ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
      : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
  };

  const customAttributes = useMemo(() => {
    return productImages.reduce((acc, img) => {
      if (img?.video !== '') {
        const onLoadedData = (evt) => {
          if (evt?.target.nodeName === 'VIDEO' && !elementIsVisibleInViewport(evt.target)) {
            evt.target.pause();
          }
        }
        return [...acc, {playsInline: true, autoPlay: true, onLoadedData}];
      }
      return [...acc, {}];
    }, []);
  }, [productImages]);

  const handleFsLightboxVideos = (instance) => {
    if (!instance?.elements?.sourceWrappersContainer?.current) return;
    const slides = instance?.elements?.sourceMainWrappers || [];
    const videos = instance.elements.sourceWrappersContainer.current.querySelector('video') ? [...instance.elements.sourceWrappersContainer.current.querySelectorAll('video')] : [];
    const currIndex = instance?.stageIndexes?.current || -1;
    videos.forEach((video) => {
      video.setAttribute('autoplay', false);
      if (!video?.paused)
        video.pause();
    });
    if (currIndex > -1 && slides?.[currIndex]) {
      const curSlide = slides[currIndex]?.current;
      if (curSlide && curSlide.querySelector('video') && curSlide.querySelector('video')?.paused) {
        curSlide.querySelector('video').play();
      }
    }
  }

  const firstImageUrl = useMemo(() => {
    if (!productImages?.[0]?.src) return null;
    return getSizedImageUrl(productImages[0].src, '600x@2x');
  }, [productImages?.[0]?.src]);

  return (
    <div
      className={`mx-auto w-full max-w-[642px] lg:max-w-none ${
        !quickView ? 'lg:pl-8' : ''
      }`}
    >
      {firstImageUrl ? (
        <Head>
          <link
            rel="preload"
            as="image"
            href={firstImageUrl}
          />
        </Head>
      ) : null}
      <div className="flex md:flex-nowrap md:items-start lg:top-[80px] lg:sticky ">
        <div
          className={`flex-shrink-0 ${
            !quickView
              ? 'pdp-thumbnails-slider-container w-[20%] max-w-[110px] lg:block lg:w-[110px] lg:pt-8'
              : 'w-20'
          }`}
        >
          <div
            className={`${
              bundleBuilder
                ? 'bb-qv-thumbnails-slider'
                : 'pdp-thumbnails-slider'
            } ${thumbnails_fix ? 'pdp-thumbnails-fix' : ''}`}
          >
            {/* placeholder thumbs before slider inits */}
            {!thumbsSliderReady && (
              <div className="flex flex-col gap-2 px-px pt-px">
                {productImages.slice(0, 4).map((productImage, index) => {
                  return (
                    <ProductThumbnail
                      productImage={productImage}
                      key={`pdp-thumbnail-image-${index}`}
                      title={product?.title}
                      index={index}
                    />
                  );
                })}
              </div>
            )}

            <Slider
              {...thumbSettings}
              ref={(slider) => setSlider2(slider)}
              infinite={!thumbnails_fix}
              onInit={() => setThumbsSliderReady(true)}
            >
              {thumbsSliderReady &&
                productImages.map((productImage, index) => {
                  return (
                    <div key={`pdp-thumbnail-image-${index}`}>
                      <ProductThumbnail
                        productImage={productImage}
                        title={product?.title}
                        index={index}
                      />
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>

        <div
          className={`flex w-full flex-1 overflow-hidden ${
            quickView ? 'lg:pl-6' : 'lg:pl-8'
          }`}
        >
          <div className="pdp-slider">
            <div className="aspect-square">
              <Slider {...mgSettings} ref={(slider) => setSlider1(slider)}>
                {productImages.map((productImage, index) => {
                  return (
                    <ProductImage
                      productImage={productImage}
                      handleFsLightbox={handleFsLightbox}
                      index={index}
                      key={index}
                      title={product?.title}
                    />
                  );
                })}
              </Slider>
            </div>
            {!quickView && (
              <span className="pdp-zoom-icon pointer-events-none absolute right-5 bottom-[68px] md:right-6 lg:bottom-6">
                <IconZoomIn />
              </span>
            )}

            {!showBWPBadge && (
              <ProductBadge
                product={product}
                className={`${quickView ? 'left-3' : 'no-bwp-badge'}`}
              />
            )}

            {showBWPBadge && (
              <ProductBadge
                product={product}
                className={`${quickView ? 'left-3' : 'has-bwp-badge'}`}
              />
            )}

            {showBWPBadge && (
              <BuyWithPrimeBadge
                className={`absolute top-auto bottom-[5.5rem] md:bottom-[5.75rem] lg:bottom-[6.5rem] left-3 lg:left-5 right-auto h-[60px] w-[60px] lg:h-[73px] lg:w-[73px]`}
              />
            )}

            <ProductCustomBadges
              product={product}
            />
          </div>
        </div>
      </div>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={sourcesFSLB}
        thumbs={thumnsFSLB}
        showThumbsOnMount
        slide={lightboxController.slide}
        customAttributes={customAttributes}
        onOpen={handleFsLightboxVideos}
        onSlideChange={handleFsLightboxVideos}
      />
    </div>
  );
}

export default ProductGallery;

function ProductThumbnail({ productImage, title, index }) {
  const image_alt = productImage?.altText || title || '';
  const image_video = productImage?.video || '';
  const imageSrc = useMemo(() => {
    return getSizedImageUrl(productImage?.src, '115x115@2x');
  }, [productImage?.src]);
  return (
    <div
      className={`pdp-thumbnails-image ${
        image_video !== '' ? 'pdp-thumbnails-image--video' : ''
      }`}
      role="button"
      tabIndex="0"
    >
      <div className="responsive-image pb-full">
        <img src={imageSrc} alt={image_alt}
          loading='eager'
          priority
          fetchpriority="high"
        />
      </div>
    </div>
  );
}

function ProductImage({ productImage, title, index, handleFsLightbox }) {
  const image_alt = productImage?.altText || title || '';
  const image_video = productImage?.video || '';
  const imageUrl = useMemo(() => {
    return getSizedImageUrl(productImage?.src, '600x@2x');
  }, [productImage?.src]);
  let HTMLoutput = null;
  if (image_video !== '') {
    if (image_video.includes('youtube.com')) {
      HTMLoutput = (
        <Link href={`${image_video}`}>
          <a
            key={`product-image-${index}`}
            className="pdp-slider-slide aspect-square pdp-slider-slide--video"
          >
            <img
              src={imageUrl}
              alt={image_alt}
              data-index={index + 1}
              onClick={(evt) => handleFsLightbox(evt)}
              priority
              fetchpriority="high"
            />
          </a>
        </Link>
      );
    } else if (image_video.includes('vimeo.com')) {
      HTMLoutput = (
        <Link href={`${image_video}`}>
          <a
            key={`product-image-${index}`}
            className="pdp-slider-slide aspect-square pdp-slider-slide--video"
          >
            <img
              src={imageUrl}
              alt={image_alt}
              data-index={index + 1}
              onClick={(evt) => handleFsLightbox(evt)}
              priority
              fetchpriority="high"
            />
          </a>
        </Link>
      );
    } else if (image_video.includes('.mp4')) {
      HTMLoutput = (
        <Link href={`${image_video}`}>
          <a
            key={`product-image-${index}`}
            className="pdp-slider-slide aspect-square pdp-slider-slide--video"
          >
            <img
              src={imageUrl}
              alt={image_alt}
              data-index={index + 1}
              onClick={(evt) => handleFsLightbox(evt)}
              priority
              fetchpriority="high"
            />
          </a>
        </Link>
      );
    }
  } else {
    HTMLoutput = (
      <Link href={`${imageUrl}`}>
        <a
          key={`product-image-${index}`}
          data-type="image"
          className="pdp-slider-slide aspect-square"
        >
          <img
            src={imageUrl}
            alt={image_alt}
            data-index={index + 1}
            onClick={(evt) => handleFsLightbox(evt)}
            priority
            fetchpriority="high"
          />
        </a>
      </Link>
    );
  }
  return HTMLoutput;
}