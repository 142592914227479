import { useEffect, useRef, useState } from 'react';
import Link from 'next/link.js';
import { useCart } from '@backpackjs/storefront';
import { Image } from '@/snippets/components/Image';
import {
  useWindowDimensions,
  newLineToBr,
  getApplicableOverride,
} from '../../utilities/Helper';
import { useProductByHandle } from '@backpackjs/storefront';
import { useCartAddItem } from '@backpackjs/storefront';
import ProductPrice from '@/snippets/globals/layout/ProductPrice';

function Section({ cms }) {
  const { vpWidth } = useWindowDimensions();
  const [heroProduct, setHeroProduct] = useState(null);
  const cart = useCart();
  const is_desktop = vpWidth > 640;
  const { headingTag, introHeading_m, heading_m, description_m, aboveTheFold = false } = cms;
  let image_d = cms?.background_d?.src;
  let image_m = cms?.background_m?.src;
  let {
    image_alt,
    introHeading,
    introHeadingSize,
    introHeadingSize_m,
    heading,
    headingSize,
    headingSize_m,
    description,
    descriptionSize,
    descriptionSize_m,
    productData,
    cta1,
    cta2,
  } = cms;
  if (introHeading_m && !is_desktop) {
    introHeading = introHeading_m;
  }
  if (heading_m && !is_desktop) {
    heading = heading_m;
  }
  if (description_m && !is_desktop) {
    description = description_m;
  }

  const {
    box_h_position,
    box_v_position,
    box_h_position_m,
    box_v_position_m,
    textAlign,
    textAlign_m,
    textColor,
    textColor_m,
    overrides,
  } = cms;
  const { video_d, video_m } = cms;
  const isFullwidth = cms?.isFullwidth || false;
  const applicableOverride = getApplicableOverride(cart, overrides);

  if (applicableOverride?.length) {
    image_d = applicableOverride[0].background_d?.src;
    image_m = applicableOverride[0].background_m?.src;
    image_alt = applicableOverride[0].image_alt;
    introHeading = applicableOverride[0]?.introHeading;
    heading = applicableOverride[0].heading;
    description = applicableOverride[0].description;
    cta1 = applicableOverride[0].cta1;
    cta2 = applicableOverride[0].cta2;
    introHeadingSize = applicableOverride[0].introHeadingSize;
    introHeadingSize_m = applicableOverride[0].introHeadingSize_m;
    headingSize = applicableOverride[0].headingSize;
    headingSize_m = applicableOverride[0].headingSize_m;
    descriptionSize = applicableOverride[0].descriptionSize;
    descriptionSize_m = applicableOverride[0].descriptionSize_m;

    if (applicableOverride[0].introHeading_m && !is_desktop) {
      introHeading = applicableOverride[0].introHeading_m;
    }
    if (applicableOverride[0].heading_m && !is_desktop) {
      heading = applicableOverride[0].heading_m;
    }
    if (applicableOverride[0].description_m && !is_desktop) {
      description = applicableOverride[0].description_m;
    }
  }

  const { fetchProductByHandle } = useProductByHandle();
  const { cartAddItem } = useCartAddItem();
  const has_video_d = video_d?.url_mp4 || video_d?.url_webm;
  const has_video_m = video_m?.url_mp4 || video_m?.url_webm;
  const has_cta1 = cta1 && cta1?.link?.url && cta1?.link?.text;
  const has_cta2 = cta2 && cta2?.link?.url && cta2?.link?.text;
  const has_cta1_withoutText = cta1 && cta1?.link?.url;
  const has_cta2_withoutText = cta2 && cta2?.link?.url;
  const refVideo = useRef(null);
  const refHero = useRef(null);
  const headingHtml = `<${headingTag} class="m-0 ${headingSize_m} ${headingSize} font-normal">${heading}</${headingTag}>`;

  useEffect(() => {
    if (!refHero.current) return;
    const heroMedia = refHero.current.querySelector('.hero__media-container');
    const heroContent = refHero.current.querySelector('.hero__content');
    if (heroMedia) heroMedia.style.removeProperty('height');
    if (heroMedia && heroContent) {
      if (heroContent.offsetHeight > heroMedia.offsetHeight) {
        heroMedia.style.height = `${heroContent.offsetHeight}px`;
      }
    }
  }, []);

  useEffect(() => {
    if(productData?.product) {
      fetchProductByHandle({handle: productData.product.handle}).then((product) => {
        setHeroProduct(product);
      });
    } else {
      setHeroProduct(null);
    }
  }, [productData?.product?.handle])

  return (
    <div
      id={`${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      className={`relative z-0 w-full${isFullwidth ? '' : ' container'}`}
      data-comp={Section.displayName}
      ref={refHero}
    >
      <style>
        {`#${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div className="hero__media-container pointer-events-none !h-full w-full lg:!h-auto">
        {/* mobile image */}
        {!has_video_m && image_m ? (
          <div
            className="relative sm:hidden"
            style={{ aspectRatio: cms.aspect_ratio_m }}
          >
            <Image
              src={image_m}
              className="absolute inset-0 h-full w-full"
              loading={aboveTheFold ? 'eager' : 'lazy'}
              fill
              sizes="100vw"
              alt={image_alt}
              priority={aboveTheFold}
              fetchpriority={aboveTheFold ? 'high' : 'low'}
            />
          </div>
        ) : null}

        {/* desktop image */}
        {!has_video_d && image_d ? (
          <div
            className="relative max-sm:hidden"
            style={{ aspectRatio: cms.aspect_ratio_d }}
          >
            <Image
              src={image_d}
              className="absolute inset-0 h-full w-full"
              loading={aboveTheFold ? 'eager' : 'lazy'}
              fill
              sizes="100vw"
              alt={image_alt}
              priority={aboveTheFold}
              fetchpriority={aboveTheFold ? 'high' : 'low'}
            />
          </div>
        ) : null}

        {/* mobile video */}
        {has_video_m && (
          <video
            className="hero__background h-full w-full object-cover sm:hidden"
            preload="auto"
            autoPlay
            muted
            loop
            playsInline
            ref={refVideo}
            style={{ aspectRatio: video_m?.aspect_ratio }}
            poster={video_m?.poster?.src}
          >
            {video_m?.url_webm && (
              <source src={video_m?.url_webm} type="video/webm" />
            )}
            {video_m?.url_mp4 && (
              <source src={video_m?.url_mp4} type="video/mp4" />
            )}
          </video>
        )}
        {/* desktop video */}
        {has_video_d && (
          <video
            className="hero__background h-full w-full object-cover max-sm:hidden"
            preload="auto"
            autoPlay
            muted
            loop
            playsInline
            ref={refVideo}
            style={{ aspectRatio: video_d?.aspect_ratio }}
            poster={video_d?.poster?.src}
          >
            {video_d?.url_webm && (
              <source src={video_d?.url_webm} type="video/webm" />
            )}
            {video_d?.url_mp4 && (
              <source src={video_d?.url_mp4} type="video/mp4" />
            )}
          </video>
        )}
      </div>
      <div
        className={`absolute left-0 top-0 flex h-full w-full ${box_v_position} ${box_v_position_m} z-1`}
      >
        {(introHeading || heading || description || has_cta1 || has_cta2) && (
          <div
            className={`hero__content sm-:py-20 container flex px-4 py-11 md:px-12 ${box_h_position} ${box_h_position_m}`}
          >
            <div
              className={`hero__block w-full max-w-[325px] overflow-hidden lg+:max-w-[432px] ${textAlign_m} ${textAlign}`}
            >
              {introHeading ? (
                <p
                  className={`hero__intro-title mt-0 mb-4 font-gothamBold ${introHeadingSize_m} font-normal ${introHeadingSize} ${textColor_m} ${textColor}`}
                >
                  {introHeading}
                </p>
              ) : null}
              {heading && (
                <div
                  dangerouslySetInnerHTML={{ __html: headingHtml }}
                  className={`hero__title mt-0 mb-3 lg:mb-4 ${textColor_m} ${textColor}`}
                />
              )}
              {description ? (
                <div
                  className={`font-normal ${descriptionSize_m} ${descriptionSize} ${textColor_m} ${textColor}`}
                  dangerouslySetInnerHTML={{ __html: newLineToBr(description) }}
                />
              ) : null}
              {heroProduct && (
                <ProductPrice variant={heroProduct.variants[0]} className={`mt-4 ${textColor_m} ${textColor}`} compareStyles='opacity-70 text-currentcolor'/>
              )}
              <div className="hero__buttons z-2 relative mt-6 inline-block w-full max-w-[225px]">
                {heroProduct && (
                  <button
                    className={`button ${productData.style} whitespace-nowrap ${(has_cta1 || has_cta2) && 'mb-4'}`}
                    onClick={() => cartAddItem({
                      merchandiseId: heroProduct.variants[0].id,
                      quantity: 1,
                      attributes: [
                        {
                          key: '_sc',
                          value: (typeof window.ShipScoutLineItemProperty !== 'undefined' ? window.ShipScoutLineItemProperty : 'null'),
                        }
                      ]
                    }).then(() => {
                      window.dispatchEvent(new CustomEvent('openSideCart'));
                    })}
                  >
                    buy now
                    <span className='ml-1'>
                      -
                      <ProductPrice variant={heroProduct.variants[0]} className='ml-1 inline-block' compareStyles='text-currentcolor'/>
                    </span>
                  </button>
                )}
                {has_cta1 ? (
                  <p className={`mt-0 ${has_cta2 ? 'mb-4' : 'mb-0'}`}>
                    <Link href={cta1?.link?.url}>
                      <a className={`${cta1?.style} text-center no-underline`}>
                        {cta1?.link?.text}
                      </a>
                    </Link>
                  </p>
                ) : null}
                {has_cta2 ? (
                  <p className="m-0">
                    <Link href={cta2?.link?.url}>
                      <a className={`${cta2?.style} text-center no-underline`}>
                        {cta2?.link?.text}
                      </a>
                    </Link>
                  </p>
                ) : null}
              </div>
              {heroProduct && productData?.showMoreInfo && (
                <div className={`hero__read-more ${textColor} ${textColor_m} mt-4`}>
                  <Link href={`/products/${heroProduct.handle}`}>
                    <a className='focus-link !text-currentcolor !border-currentcolor'>learn more</a>
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
        {(has_cta1_withoutText && !has_cta2_withoutText) ||
        (!has_cta1_withoutText && has_cta2_withoutText) ? (
          <>
            {has_cta1_withoutText ? (
              <Link href={cta1?.link?.url}>
                <a
                  className={`z-1 absolute left-0 top-0 h-full w-full ${textColor_m} ${textColor}`}
                  aria-label={cta1?.link?.text}
                />
              </Link>
            ) : null}
            {has_cta2_withoutText ? (
              <Link href={cta2?.link?.url}>
                <a
                  className={`z-1 absolute left-0 top-0 h-full w-full ${textColor_m} ${textColor}`}
                  aria-label={cta2?.link?.text}
                />
              </Link>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
}

Section.displayName = 'Hero';

Section.Schema = {
  category: 'Modules',
  label: Section.displayName,
  key: 'hero',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      component: 'toggle',
      name: 'isFullwidth',
      label: 'Full Width',
      toggleLabels: {
        true: 'Full Width',
        false: 'Grid width',
      },
      defaultValue: true,
    },
    {
      component: 'toggle',
      name: 'aboveTheFold',
      label: 'Above the fold',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: false,
    },
    {
      name: 'background_d',
      label: 'Background Image (Desktop)',
      component: 'image',
      description:
        'Recommended Image Size: 2880px x 1340px (height can be variable)',
    },
    {
      name: 'aspect_ratio_d',
      label: 'Image Aspect Ratio (Desktop)',
      description: 'Width divided by height, e.g. 16:9 image is "1.78"',
      component: 'number',
    },
    {
      name: 'background_m',
      label: 'Background Image (Mobile)',
      component: 'image',
      description:
        'Recommended Image Size: 750px x 1400px (height can be variable)',
    },
    {
      name: 'aspect_ratio_m',
      label: 'Image Aspect Ratio (Mobile)',
      description: 'Width divided by height, e.g. 16:9 image is "1.78"',
      component: 'number',
    },
    {
      name: 'image_alt',
      label: 'Image alt text',
      component: 'text',
    },
    {
      label: 'Background Video (Desktop)',
      name: 'video_d',
      component: 'group',
      fields: [
        {
          name: 'aspect_ratio',
          label: 'Aspect Ratio',
          description: 'Width divided by height, e.g. 16:9 video is "1.78"',
          component: 'number',
        },
        {
          name: 'url_mp4',
          label: 'Mp4 Video URL',
          component: 'text',
        },
        {
          name: 'url_webm',
          label: 'Webm Video URL',
          component: 'text',
        },
        {
          name: 'poster',
          label: 'Poster Image',
          component: 'image',
          description: 'First frame of the video while video loads',
        },
      ],
    },
    {
      label: 'Background Video (Mobile)',
      name: 'video_m',
      component: 'group',
      fields: [
        {
          name: 'aspect_ratio',
          label: 'Aspect Ratio',
          description: 'Width divided by height, e.g. 16:9 video is "1.78"',
          component: 'number',
        },
        {
          name: 'url_mp4',
          label: 'Mp4 Video URL',
          component: 'text',
        },
        {
          name: 'url_webm',
          label: 'Webm Video URL',
          component: 'text',
        },
        {
          name: 'poster',
          label: 'Poster Image',
          component: 'image',
          description: 'First frame of the video while video loads',
        },
      ],
    },
    {
      component: 'select',
      name: 'box_v_position',
      label: 'Content Block Vertical Position (Desktop)',
      options: [
        { label: 'Top', value: 'sm:items-start' },
        { label: 'Middle', value: 'sm:items-center' },
        { label: 'Bottom', value: 'sm:items-end' },
      ],
      defaultValue: 'sm:items-start',
    },
    {
      component: 'select',
      name: 'box_h_position',
      label: 'Content Block Horizontal Position (Desktop)',
      options: [
        { label: 'Left', value: 'sm:justify-start md:pl-[114px]' },
        { label: 'Center', value: 'sm:justify-center' },
        { label: 'Right', value: 'sm:justify-end md:pr-[114px]' },
      ],
      defaultValue: 'sm:justify-start',
    },
    {
      component: 'select',
      name: 'textAlign',
      label: 'Text Align (Desktop)',
      options: [
        { label: 'Left', value: 'sm:text-left' },
        { label: 'Center', value: 'sm:text-center' },
        { label: 'Right', value: 'sm:text-right' },
      ],
      defaultValue: 'sm:text-left',
    },
    {
      component: 'select',
      name: 'textColor',
      label: 'Text Color (Desktop)',
      options: [
        { label: 'Light', value: 'sm:text-white' },
        { label: 'Dark', value: 'sm:text-black' },
      ],
      defaultValue: 'sm:text-black',
    },
    {
      component: 'select',
      name: 'box_v_position_m',
      label: 'Content Block Vertical Position (Mobile)',
      options: [
        { label: 'Top', value: 'items-start' },
        { label: 'Middle', value: 'items-center' },
        { label: 'Bottom', value: 'items-end' },
      ],
      defaultValue: 'items-start',
    },
    {
      component: 'select',
      name: 'box_h_position_m',
      label: 'Content Block Horizontal Position (Mobile)',
      options: [
        { label: 'Left', value: 'justify-start' },
        { label: 'Center', value: 'justify-center' },
        { label: 'Right', value: 'justify-end' },
      ],
      defaultValue: 'justify-start',
    },
    {
      component: 'select',
      name: 'textAlign_m',
      label: 'Text Align (Mobile)',
      options: [
        { label: 'Left', value: 'text-left' },
        { label: 'Center', value: 'text-center' },
        { label: 'Right', value: 'text-right' },
      ],
      defaultValue: 'text-left',
    },
    {
      component: 'select',
      name: 'textColor_m',
      label: 'Text Color (Mobile)',
      options: [
        { label: 'Light', value: 'text-white' },
        { label: 'Dark', value: 'text-black' },
      ],
      defaultValue: 'text-black',
    },
    {
      component: 'select',
      name: 'headingTag',
      label: 'Heading Tag',
      options: [
        { label: 'H1', value: 'h1' },
        { label: 'H2', value: 'h2' },
        { label: 'H3', value: 'h3' },
      ],
      defaultValue: 'h2',
      description:
        'If the section is first then the heading should be an H1 tag. Note: Heading styles will be the same no matter what the tag value is. This is for ADA + SEO.',
    },
    {
      name: 'introHeading',
      label: 'Intro Heading (Desktop)',
      component: 'text',
      defaultValue: 'Optional intro title here',
    },
    {
      name: 'introHeadingSize',
      label: 'Intro Heading Size (Desktop)',
      component: 'select',
      options: [
        {
          label: 'Large',
          value: 'lg+:sub-large',
        },
        {
          label: 'Regular',
          value: 'lg+:sub-regular',
        },
        {
          label: 'Small',
          value: 'lg+:sub-small',
        },
        {
          label: 'Xsmall',
          value: 'lg+:sub-xsmall',
        },
        {
          label: 'Xxsmall',
          value: 'lg+:sub-xxsmall',
        },
      ],
      defaultValue: 'lg+:sub-large',
    },
    {
      name: 'heading',
      label: 'Heading (Desktop)',
      component: 'text',
      defaultValue: 'Clean chemistry. Healthy hair.',
    },
    {
      name: 'headingSize',
      label: 'Heading Size (Desktop)',
      component: 'select',
      options: [
        {
          label: 'Heading One',
          value: 'lg+:text-h1',
        },
        {
          label: 'Heading Two',
          value: 'lg+:text-h2',
        },
        {
          label: 'Heading Three',
          value: 'lg+:text-h3',
        },
        {
          label: 'Heading Four',
          value: 'lg+:text-h4',
        },
        {
          label: 'Heading Five',
          value: 'lg+:text-h5',
        },
        {
          label: 'Heading Six',
          value: 'lg+:text-h6',
        },
      ],
      defaultValue: 'lg+:text-h2',
    },
    {
      name: 'description',
      label: 'Description (Desktop)',
      component: 'textarea',
      defaultValue:
        'We create powerful, naturally-derived solutions that are proven to improve hair’s health, for every type and texture.',
    },
    {
      name: 'descriptionSize',
      label: 'Description Size (Desktop)',
      component: 'select',
      options: [
        {
          label: 'Text Size One',
          value: 'lg+:text-large',
        },
        {
          label: 'Text Size Two',
          value: 'lg+:text-regular',
        },
        {
          label: 'Text Size Three',
          value: 'lg+:text-small',
        },
        {
          label: 'Text Size Four',
          value: 'lg+:text-xsmall',
        },
      ],
      defaultValue: 'lg+:text-large',
    },
    {
      name: 'introHeading_m',
      label: 'Intro Heading (Mobile)',
      component: 'text',
      defaultValue: 'Optional intro title here',
    },
    {
      name: 'introHeadingSize_m',
      label: 'Intro Heading Size (Mobile)',
      component: 'select',
      options: [
        {
          label: 'Large',
          value: 'sub-large',
        },
        {
          label: 'Regular',
          value: 'sub-regular',
        },
        {
          label: 'Small',
          value: 'sub-small',
        },
        {
          label: 'Xsmall',
          value: 'sub-xsmall',
        },
        {
          label: 'Xxsmall',
          value: 'sub-xxsmall',
        },
      ],
      defaultValue: 'sub-small',
    },
    {
      name: 'heading_m',
      label: 'Heading (Mobile)',
      component: 'text',
      defaultValue: 'Clean chemistry. Healthy hair.',
    },
    {
      name: 'headingSize_m',
      label: 'Heading Size (Mobile)',
      component: 'select',
      options: [
        {
          label: 'Heading One',
          value: 'text-h1-m',
        },
        {
          label: 'Heading Two',
          value: 'text-h2-m',
        },
        {
          label: 'Heading Three',
          value: 'text-h3-m',
        },
        {
          label: 'Heading Four',
          value: 'text-h4-m',
        },
        {
          label: 'Heading Five',
          value: 'text-h5-m',
        },
        {
          label: 'Heading Six',
          value: 'text-h6-m',
        },
      ],
      defaultValue: 'text-h1-m',
    },
    {
      name: 'description_m',
      label: 'Description (Mobile)',
      component: 'textarea',
      defaultValue:
        'We create powerful, naturally-derived solutions that are proven to improve hair’s health, for every type and texture.',
    },
    {
      name: 'descriptionSize_m',
      label: 'Description Size (Mobile)',
      component: 'select',
      options: [
        {
          label: 'Text Size One',
          value: 'text-large',
        },
        {
          label: 'Text Size Two',
          value: 'text-regular',
        },
        {
          label: 'Text Size Three',
          value: 'text-small',
        },
        {
          label: 'Text Size Four',
          value: 'text-xsmall',
        },
      ],
      defaultValue: 'text-regular',
    },
    {
      component: 'group',
      name: 'productData',
      label: 'Product Data',
      description: 'Settings related to adding a product button to the hero',
      fields: [
        {
          component: 'productSearch',
          name: 'product',
          label: 'Product',
          description: 'Select a product to turn the hero into a CTA module that adds the product to the cart',
        },
        {
          component: 'select',
          name: 'style',
          label: 'Button Style',
          options: [
            { label: 'Primary', value: 'button button--primary inline-block' },
            { label: 'Primary - Air Dry Cream', value: 'button button--primary-stylers-1 inline-block' },
            { label: 'Primary - Sculpting Cream', value: 'button button--primary-stylers-2 inline-block' },
            { label: 'Primary - Texture Foam', value: 'button button--primary-stylers-3 inline-block' },
            { label: 'Primary - Sleek Stick', value: 'button button--primary-stylers-4 inline-block' },
            {
              label: 'Secondary',
              value: 'button button--secondary inline-block',
            },
            {
              label: 'Tertiary',
              value: 'button button--tertiary inline-block',
            },
            { label: 'Focus Link', value: 'focus-link' },
            { label: 'Focus Link White', value: 'focus-link-white' },
            {
              label: 'checkout',
              value: 'button button--checkout inline-block',
            },
          ],
          defaultValue: 'button button--primary inline-block',
        },
        {
          component: 'toggle',
          name: 'showMoreInfo',
          label: 'Show More Info Button',
          description: 'Adds a button below the main CTA that links to the product page',
          toggleLabels: {
            true: 'Yes',
            false: 'No',
          },
        }
      ]
    },
    {
      label: 'CTA 1',
      name: 'cta1',
      component: 'group',
      fields: [
        {
          label: 'CTA URL',
          name: 'link',
          component: 'link',
        },
        {
          component: 'select',
          name: 'style',
          label: 'Cta Style',
          options: [
            { label: 'Primary', value: 'button button--primary inline-block' },
            { label: 'Primary - Air Dry Cream', value: 'button button--primary-stylers-1 inline-block' },
            { label: 'Primary - Sculpting Cream', value: 'button button--primary-stylers-2 inline-block' },
            { label: 'Primary - Texture Foam', value: 'button button--primary-stylers-3 inline-block' },
            { label: 'Primary - Sleek Stick', value: 'button button--primary-stylers-4 inline-block' },
            {
              label: 'Secondary',
              value: 'button button--secondary inline-block',
            },
            {
              label: 'Tertiary',
              value: 'button button--tertiary inline-block',
            },
            { label: 'Focus Link', value: 'focus-link' },
            { label: 'Focus Link White', value: 'focus-link-white' },
            {
              label: 'checkout',
              value: 'button button--checkout inline-block',
            },
          ],
          defaultValue: 'button button--primary inline-block',
        }
      ],
    },
    {
      label: 'CTA 2',
      name: 'cta2',
      component: 'group',
      fields: [
        {
          label: 'CTA URL',
          name: 'link',
          component: 'link',
        },
        {
          component: 'select',
          name: 'style',
          label: 'Cta Style',
          options: [
            { label: 'Primary', value: 'button button--primary inline-block' },
            { label: 'Primary - Air Dry Cream', value: 'button button--primary-stylers-1 inline-block' },
            { label: 'Primary - Sculpting Cream', value: 'button button--primary-stylers-2 inline-block' },
            { label: 'Primary - Texture Foam', value: 'button button--primary-stylers-3 inline-block' },
            { label: 'Primary - Sleek Stick', value: 'button button--primary-stylers-4 inline-block' },
            {
              label: 'Secondary',
              value: 'button button--secondary inline-block',
            },
            {
              label: 'Tertiary',
              value: 'button button--tertiary inline-block',
            },
            { label: 'Focus Link', value: 'focus-link' },
            { label: 'Focus Link White', value: 'focus-link-white' },
            {
              label: 'checkout',
              value: 'button button--checkout inline-block',
            },
          ],
          defaultValue: 'button button--primary inline-block',
        },
      ],
    },
    {
      component: 'group-list',
      name: 'overrides',
      label: 'Hero overrides',
      description:
        'Describes the text cases when a discount code is applied on the cart',
      fields: [
        {
          component: 'text',
          name: 'discountCode',
          label: 'Discount code reference',
          description:
            'Discount code that needs to be present on the cart in order to trigger this override',
        },
        {
          name: 'background_d',
          label: 'Background Image (Desktop)',
          component: 'image',
          description:
            'Recommended Image Size: 2880px x 1340px (height can be variable)',
        },
        {
          name: 'background_m',
          label: 'Background Image (Mobile)',
          component: 'image',
          description:
            'Recommended Image Size: 750px x 1400px (height can be variable)',
        },
        {
          name: 'image_alt',
          label: 'Image alt text',
          component: 'text',
        },
        {
          name: 'introHeading',
          label: 'Intro Heading (Desktop)',
          component: 'text',
          defaultValue: 'Optional intro title here',
        },
        {
          name: 'introHeadingSize',
          label: 'Intro Heading Size (Desktop)',
          component: 'select',
          options: [
            {
              label: 'Large',
              value: 'lg+:sub-large',
            },
            {
              label: 'Regular',
              value: 'lg+:sub-regular',
            },
            {
              label: 'Small',
              value: 'lg+:sub-small',
            },
            {
              label: 'Xsmall',
              value: 'lg+:sub-xsmall',
            },
            {
              label: 'Xxsmall',
              value: 'lg+:sub-xxsmall',
            },
          ],
          defaultValue: 'lg+:sub-large',
        },
        {
          name: 'heading',
          label: 'Heading (Desktop)',
          component: 'text',
          defaultValue: 'Clean chemistry. Healthy hair.',
        },
        {
          name: 'headingSize',
          label: 'Heading Size (Desktop)',
          component: 'select',
          options: [
            {
              label: 'Heading One',
              value: 'lg+:text-h1',
            },
            {
              label: 'Heading Two',
              value: 'lg+:text-h2',
            },
            {
              label: 'Heading Three',
              value: 'lg+:text-h3',
            },
            {
              label: 'Heading Four',
              value: 'lg+:text-h4',
            },
            {
              label: 'Heading Five',
              value: 'lg+:text-h5',
            },
            {
              label: 'Heading Six',
              value: 'lg+:text-h6',
            },
          ],
          defaultValue: 'lg+:text-h2',
        },
        {
          name: 'description',
          label: 'Description (Desktop)',
          component: 'textarea',
          defaultValue:
            'We create powerful, naturally-derived solutions that are proven to improve hair’s health, for every type and texture.',
        },
        {
          name: 'descriptionSize',
          label: 'Description Size (Desktop)',
          component: 'select',
          options: [
            {
              label: 'Text Size One',
              value: 'lg+:text-large',
            },
            {
              label: 'Text Size Two',
              value: 'lg+:text-regular',
            },
            {
              label: 'Text Size Three',
              value: 'lg+:text-small',
            },
            {
              label: 'Text Size Four',
              value: 'lg+:text-xsmall',
            },
          ],
          defaultValue: 'lg+:text-large',
        },
        {
          name: 'introHeading_m',
          label: 'Intro Heading (Mobile)',
          component: 'text',
          defaultValue: 'Optional intro title here',
        },
        {
          name: 'introHeadingSize_m',
          label: 'Intro Heading Size (Mobile)',
          component: 'select',
          options: [
            {
              label: 'Large',
              value: 'sub-large',
            },
            {
              label: 'Regular',
              value: 'sub-regular',
            },
            {
              label: 'Small',
              value: 'sub-small',
            },
            {
              label: 'Xsmall',
              value: 'sub-xsmall',
            },
            {
              label: 'Xxsmall',
              value: 'sub-xxsmall',
            },
          ],
          defaultValue: 'sub-small',
        },
        {
          name: 'heading_m',
          label: 'Heading (Mobile)',
          component: 'text',
          defaultValue: 'Clean chemistry. Healthy hair.',
        },
        {
          name: 'headingSize_m',
          label: 'Heading Size (Mobile)',
          component: 'select',
          options: [
            {
              label: 'Heading One',
              value: 'text-h1-m',
            },
            {
              label: 'Heading Two',
              value: 'text-h2-m',
            },
            {
              label: 'Heading Three',
              value: 'text-h3-m',
            },
            {
              label: 'Heading Four',
              value: 'text-h4-m',
            },
            {
              label: 'Heading Five',
              value: 'text-h5-m',
            },
            {
              label: 'Heading Six',
              value: 'text-h6-m',
            },
          ],
          defaultValue: 'text-h1-m',
        },
        {
          name: 'description_m',
          label: 'Description (Mobile)',
          component: 'textarea',
          defaultValue:
            'We create powerful, naturally-derived solutions that are proven to improve hair’s health, for every type and texture.',
        },
        {
          name: 'descriptionSize_m',
          label: 'Description Size (Mobile)',
          component: 'select',
          options: [
            {
              label: 'Text Size One',
              value: 'text-large',
            },
            {
              label: 'Text Size Two',
              value: 'text-regular',
            },
            {
              label: 'Text Size Three',
              value: 'text-small',
            },
            {
              label: 'Text Size Four',
              value: 'text-xsmall',
            },
          ],
          defaultValue: 'text-regular',
        },
        {
          label: 'CTA 1',
          name: 'cta1',
          component: 'group',
          fields: [
            {
              label: 'CTA URL',
              name: 'link',
              component: 'link',
            },
            {
              component: 'select',
              name: 'style',
              label: 'Cta Style',
              options: [
                { label: 'Primary', value: 'button button--primary inline-block' },
                {
                  label: 'Secondary',
                  value: 'button button--secondary inline-block',
                },
                {
                  label: 'Tertiary',
                  value: 'button button--tertiary inline-block',
                },
                { label: 'Focus Link', value: 'focus-link' },
                { label: 'Focus Link White', value: 'focus-link-white' },
                {
                  label: 'checkout',
                  value: 'button button--checkout inline-block',
                },
              ],
              defaultValue: 'button button--primary inline-block',
            },
          ],
        },
        {
          label: 'CTA 2',
          name: 'cta2',
          component: 'group',
          fields: [
            {
              label: 'CTA URL',
              name: 'link',
              component: 'link',
            },
            {
              component: 'select',
              name: 'style',
              label: 'Cta Style',
              options: [
                { label: 'Primary', value: 'button button--primary inline-block' },
                {
                  label: 'Secondary',
                  value: 'button button--secondary inline-block',
                },
                {
                  label: 'Tertiary',
                  value: 'button button--tertiary inline-block',
                },
                { label: 'Focus Link', value: 'focus-link' },
                { label: 'Focus Link White', value: 'focus-link-white' },
                {
                  label: 'checkout',
                  value: 'button button--checkout inline-block',
                },
              ],
              defaultValue: 'button button--primary inline-block',
            },
          ],
        },
      ],
    },
  ],
};

export const Hero = Section;
